// Declare any keys that the app needs to have control over in the local- or sessionstorage
export const SEARCHBAR_KEY = 'searchBar';
export const DESKMODE_KEY = 'deskmode';
export const DEVICE_ID = 'deviceId';
export const CART_KEY = 'cartId';
export const ACCESS_TOKEN = 'access_token';
export const IS_GUEST = 'is_guest';
export const VISITOR_PARTY = 'visitor_party';
export const REFRESH_TOKEN = 'refresh_token';
export const DATE_STAMP = 'dateStamp';
