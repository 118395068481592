import DeviceSizeProvider from '@oberoninternal/travelbase-ds/context/devicesize';
import DSGlobalStyling from '@oberoninternal/travelbase-ds/globalStyling';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Router } from 'next/router';
import React, { FC, useEffect, useMemo } from 'react';
import { createIntl, RawIntlProvider } from 'react-intl';
import URL from 'url-parse';
import formats from '../constants/formats';
import standAlonePages from '../constants/standalonePages';
import { CheckoutProvider } from '../context/checkout';
import { QueryParamProvider } from '../context/QueryParamProvider';
import { useTenantContext } from '../context/TenantContext';
import { Locale } from '../entities/Locale';
import GlobalStyling from '../globalStyle';
import usePageView from '../hooks/usePageview';
import cache from '../intlCache';
import '../yupExtensions';
import Cookies from 'js-cookie';
import { TRAVELBASE_AFF, TRAVELBASE_AFF_REFERENCE } from '../constants/affiliate';
import Toasts from './Toasts';
import { DEVICE_ID } from '../constants/storage';

const DeskMode = dynamic(() => import('./DeskMode'), { ssr: false });
const GlobalStylings = () => {
    const { brandConfig } = useTenantContext();

    return (
        <>
            <DSGlobalStyling />
            <GlobalStyling />
            {brandConfig.globalStyling && (
                <Head>
                    {/* eslint-disable-next-line react/no-danger */}
                    <style dangerouslySetInnerHTML={{ __html: brandConfig.globalStyling }} />
                </Head>
            )}
        </>
    );
};

type Props = AppProps;

const TorApp: FC<React.PropsWithChildren<Props>> = props => {
    const { router } = props;
    const { query } = router;
    const { messages, locale, brandConfig } = useTenantContext();
    usePageView();
    useEffect(() => {
        const { affiliateHandle, affiliateReference } = query;
        if (affiliateHandle && affiliateHandle.length > 0) {
            Cookies.set(TRAVELBASE_AFF, affiliateHandle as string, { expires: 60 });
            delete query.affiliateHandle;
            if (affiliateReference) {
                Cookies.set(TRAVELBASE_AFF_REFERENCE, affiliateReference as string, { expires: 60 });
                delete query.affiliateReference;
            } else {
                Cookies.remove(TRAVELBASE_AFF_REFERENCE);
            }
            router.replace({ pathname: router?.pathname, query }, undefined, {
                shallow: true,
            });
        }
    }, [router, query]);
    useEffect(() => {
        const { sellDeviceId } = query;
        if (sellDeviceId) {
            localStorage.setItem(DEVICE_ID, sellDeviceId as string);
        }
    }, [query]);
    const { Navigation, Footer } = brandConfig.customContent ?? {};
    const intl = useMemo(
        () =>
            createIntl(
                {
                    locale,
                    messages,
                    formats,
                },
                cache
            ),
        [locale, messages]
    );

    useEffect(() => {
        Router.events.on('beforeHistoryChange', (url: string) => {
            // only jump to top if pathname changes
            if (new URL(url).pathname !== window.location.pathname) {
                window.scrollTo(0, 0);
            }
        });

        if (brandConfig.template?.onRouteChange) {
            const { onRouteChange } = brandConfig.template;
            onRouteChange();
            Router.events.on('routeChangeComplete', () => {
                onRouteChange();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // a standalone page is for example the Planner or SearchBox page
    const hideDeskmode = standAlonePages.some(page => router.pathname.endsWith(page));
    const shouldRenderGTMScript = !brandConfig.template?.disableGTMWhenActive || hideDeskmode;
    return (
        <RawIntlProvider value={intl}>
            <GlobalStylings />
            <Head>
                {brandConfig.seo.twitterUsername && (
                    <meta name="twitter:site" content={brandConfig.seo.twitterUsername} />
                )}
                {brandConfig.seo.siteDescription?.[locale as Locale] && (
                    <meta name="description" content={brandConfig.seo.siteDescription[locale as Locale]} />
                )}
                {brandConfig.seo.homeTitle?.[locale as Locale] && (
                    <title>{brandConfig.seo.homeTitle[locale as Locale]}</title>
                )}
                <meta property="og:locale" content={locale} />
                <meta
                    property="og:url"
                    content={`${process.env.NEXT_PUBLIC_BASE_URL ?? ''}/${router.locale}${router.asPath}`}
                />
                <meta property="og:type" content="website" />

                {brandConfig.gtmID && shouldRenderGTMScript && (
                    <script
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${
                            typeof brandConfig.gtmID === 'string'
                                ? brandConfig.gtmID
                                : brandConfig.gtmID[locale as Locale]
                        }');`,
                        }}
                        type="text/javascript"
                    />
                )}
            </Head>
            <DeviceSizeProvider>
                <QueryParamProvider>
                    <CheckoutProvider>
                        {Navigation && <Navigation />}
                        {!hideDeskmode && <DeskMode />}
                        {props.children}
                        {Footer && <Footer />}
                    </CheckoutProvider>
                </QueryParamProvider>
            </DeviceSizeProvider>
            <Toasts />
        </RawIntlProvider>
    );
};

export default TorApp;
