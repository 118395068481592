import { useCallback, useEffect, useState } from 'react';

// OPTION TYPES

export interface SearchEmbedOptions {
    /**
     * The initial search parameters to filter on,
     * e.g. `{ accommodationType: 'all' }`
     * @default ''
     */
    searchParams: string | Record<string, string>;
    /**
     * The initial type of view to show
     * @default 'list'
     */
    viewType: 'map' | 'list';
    /**
     * Enable/disable the left side filter bar
     * @default true
     */
    enableFilters: boolean;
    /**
     * Enable/disable the search box that allows you to change dates/travel party
     * @default true
     */
    enableSearch: boolean;
    /**
     * Enable/disable the sorting type buttons
     * @default true
     */
    enableSort: boolean;
    /**
     * Enable/disable the view type buttons (map/list)
     * @default true
     */
    enableViewType: boolean;
}

export interface PlannerEmbedOptions {
    /**
     * The slug of the rental unit to show the planner for
     */
    rentalUnitSlug: string;
}

// CALL BACK TYPES

export interface SearchEmbedCallbacks {
    /**
     * Triggered when a search result is clicked, can be used to navigate to a different page for example.
     *
     * ```js
     * onHitClick: (unit, searchParams) => {
     *   window.location.href = `/accommodation/${unit.slug}?${searchParams}`;
     * }
     * ```
     */
    onHitClick?: (unit: { slug: string; id: string }, searchParams: string) => void;
}

// export interface PlannerEmbedCallbacks {
//     /**
//      * Triggered when no availability was found for the next 3 months.
//      */
//     onNoAvailability?: () => void;
// }

export interface SearchBoxEmbedCallbacks {
    /**
     * Triggered when the search box is submitted.
     */
    onSubmit?: (searchParams: string) => void;
}

// EVENT TYPES

interface IframeReadyEvent {
    type: 'iframeReady';
}

interface SetSearchEmbedOptionsEvent {
    type: 'setSearchEmbedOptions';
    options: SearchEmbedOptions;
}
interface SetPlannerEmbedOptionsEvent {
    type: 'setPlannerEmbedOptions';
    options: PlannerEmbedOptions;
}
type TriggerSearchEmbedCallbackEvent = {
    [K in keyof SearchEmbedCallbacks]: {
        type: 'triggerSearchCallback';
        name: K;
        args: Parameters<Required<SearchEmbedCallbacks>[K]>;
    };
}[keyof SearchEmbedCallbacks];
type TriggerSearchBoxEmbedCallbackEvent = {
    [K in keyof SearchBoxEmbedCallbacks]: {
        type: 'triggerSearchBoxCallback';
        name: K;
        args: Parameters<Required<SearchBoxEmbedCallbacks>[K]>;
    };
}[keyof SearchBoxEmbedCallbacks];
interface IframeDimensionsEvent {
    type: 'iframeDimensions';
    dimensions: IframeDimensions;
}
interface IframeFullScreenToggleEvent {
    type: 'iframeFullScreenToggle';
    fullscreen: boolean;
}
export interface IframeDimensions {
    width: number;
    height: number;
    scrollHeight: number;
    scrollWidth: number;
}
export declare type EmbedEvent = { source: 'travelbase' } & (
    | IframeReadyEvent
    | IframeDimensionsEvent
    | IframeFullScreenToggleEvent
    | SetSearchEmbedOptionsEvent
    | SetPlannerEmbedOptionsEvent
    | TriggerSearchEmbedCallbackEvent
    | TriggerSearchBoxEmbedCallbackEvent
);

// HOOKS

export const useSendEvent = () =>
    useCallback((event: EmbedEvent) => {
        window.parent.postMessage(event, '*');
    }, []);

const useEmbedEvents = () => {
    const [searchEmbedOptions, setSearchEmbedOptions] = useState<SearchEmbedOptions>({
        searchParams: '',
        viewType: 'list',
        enableFilters: true,
        enableSearch: true,
        enableSort: true,
        enableViewType: true,
    });
    const [plannerEmbedOptions, setPlannerEmbedOptions] = useState<PlannerEmbedOptions | null>(null);
    const sendEvent = useSendEvent();
    const onMessage = (e: MessageEvent) => {
        const event = e.data as EmbedEvent;
        if (event.source !== 'travelbase') {
            return;
        }
        switch (event.type) {
            case 'setSearchEmbedOptions': {
                setSearchEmbedOptions(opts => ({ ...opts, ...event.options }));
                break;
            }
            case 'setPlannerEmbedOptions': {
                setPlannerEmbedOptions(event.options);
                break;
            }
            default:
                break;
        }
    };
    const triggerSearchCallback = (
        name: keyof SearchEmbedCallbacks,
        ...args: Parameters<Required<SearchEmbedCallbacks>[keyof SearchEmbedCallbacks]>
    ) => {
        sendEvent({
            type: 'triggerSearchCallback',
            name,
            args,
            source: 'travelbase',
        });
    };
    const triggerSearchBoxCallback = (
        name: keyof SearchBoxEmbedCallbacks,
        ...args: Parameters<Required<SearchBoxEmbedCallbacks>[keyof SearchBoxEmbedCallbacks]>
    ) => {
        sendEvent({
            type: 'triggerSearchBoxCallback',
            name,
            args,
            source: 'travelbase',
        });
    };
    useEffect(() => {
        window.addEventListener('message', onMessage);
        sendEvent({ type: 'iframeReady', source: 'travelbase' });
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [sendEvent]);
    useEffect(() => {
        const targetNode = document.body;

        const callback = () => {
            const rect = document.body.getBoundingClientRect();
            sendEvent({
                type: 'iframeDimensions',
                dimensions: {
                    width: rect.width,
                    height: rect.height,
                    scrollHeight: document.body.scrollHeight,
                    scrollWidth: document.body.scrollWidth,
                },
                source: 'travelbase',
            });
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, { childList: true, subtree: true, attributes: true });
        return () => {
            observer.disconnect();
        };
    }, [sendEvent]);
    return {
        sendEvent,
        searchEmbedOptions,
        plannerEmbedOptions,
        triggerSearchCallback,
        triggerSearchBoxCallback,
    };
};
export default useEmbedEvents;
